// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component116Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc1_1_1x0_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x0_1_9_0_1_onClick ")
                }  
              };

const bloc1_1_1x1_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x1_1_9_0_1_onClick ")
                }  
              };

const bloc1_1_1x2_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x2_1_9_0_1_onClick ")
                }  
              };

const bloc1_1_1x3_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x3_1_9_0_1_onClick ")
                }  
              };

const bloc1_1_1x4_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x4_1_9_0_1_onClick ")
                }  
              };

const bloc1_1_1x5_1_9_0_1_onClick = async (e) => {
                try{ 
                     window.open('mailto:contact@appisyou.fr?subject=Demande de stage','_blank');
                }
                catch(e){
                  console.log("Error bloc1_1_1x5_1_9_0_1_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "flex flex-row items-center bg-zinc-700 safearea w-screen bg-primary_color font-app-1"}     > <img  id='bloc0_0'  className={ "w-10 h-10 rounded-xl m-2   object-cover"}  src="/profiles/ploy26/user.png"   alt="undefined" />
          <div id='bloc0_1'  className={ "p-2 text-white font-bold"}     >{`APPISYOU RECRUTEMENT`} </div></div><div id='bloc1'  className={ "bg-white"}     > <div id='bloc1_0'       > </div>
          <div id='bloc1_1'  className={ "p-2 w-screen flex flex-col gap-12 justify-center items-center px-6 p-2 font-app-1"}     > <div id='bloc1_1_0'  className={ "text-4xl font-bold text-primary_color mt-12"}     >{`AppIsYou recrute`} </div>
            <div id='bloc1_1_1x0'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x0_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x0_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/agenda1726419099996.jpg"   alt="undefined" />
                <div id='bloc1_1_1x0_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Développeur Fullstack - Module d'Agenda`} </div></div>
              <div id='bloc1_1_1x0_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg"}     > <div id='bloc1_1_1x0_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x0_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x0_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x0_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x0_1_0_1_1'  className={ "text-left"}     >{`23/09/2024`} </div></div></div>
                <div id='bloc1_1_1x0_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x0_1_1_0'  className={ "p-2 "}     >{`Informatique - Développement Fullstack`} </div>
                  <div id='bloc1_1_1x0_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x0_1_2'  className={ "p-2 text-left"}     >{`Développeur Fullstack - Module d'Agenda`} </div>
                <div id='bloc1_1_1x0_1_3'  className={ "p-2 text-left font-bold"}     >{`Nous recherchons un développeur fullstack pour participer au développement d'un agenda avec fonctionnalité de messagerie instantanée, synchronisé avec des calendriers externes via Google Calendar API. Vous travaillerez également sur l'intégration de ce module dans la plateforme AppIsYou.`} </div>
                <div id='bloc1_1_1x0_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x0_1_4_0x0'  className={ "p-1 text-sm"}     >{`Développement d'un agenda interactif avec fonctionnalité de messagerie instantanée`} </div>
          <div id='bloc1_1_1x0_1_4_0x1'  className={ "p-1 text-sm"}     >{`Mise en place de la synchronisation avec les API de calendrier (Google Calendar)`} </div>
          <div id='bloc1_1_1x0_1_4_0x2'  className={ "p-1 text-sm"}     >{`Intégration d'une base de données NoSQL pour la gestion des messages`} </div>
          <div id='bloc1_1_1x0_1_4_0x3'  className={ "p-1 text-sm"}     >{`Intégration du module dans la plateforme AppIsYou`} </div></div>
                <div id='bloc1_1_1x0_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x0_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`React`} </div>
          <div id='bloc1_1_1x0_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Node.js`} </div>
          <div id='bloc1_1_1x0_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`API REST`} </div>
          <div id='bloc1_1_1x0_1_5_0x3'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Google Calendar API`} </div>
          <div id='bloc1_1_1x0_1_5_0x4'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`MongoDB ou CouchDB`} </div></div>
                <div id='bloc1_1_1x0_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x0_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x0_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x0_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x0_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x0_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x0_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x0_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x0_1_8'  className={ "p-2 "}     >{`Grande motivation pour les nouvelles technologies`} </div>
                <div id='bloc1_1_1x0_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-primary_color text-white"}     > <div id='bloc1_1_1x0_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x0_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x0_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton bg-white text-primary_color"}    onClick = { bloc1_1_1x0_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x0_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div>
    <div id='bloc1_1_1x1'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x1_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x1_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/auth31726422624527.png"   alt="undefined" />
                <div id='bloc1_1_1x1_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Designer UI/UX - Portail d'authentification`} </div></div>
              <div id='bloc1_1_1x1_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg bg-primary_color text-white"}     > <div id='bloc1_1_1x1_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x1_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x1_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x1_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x1_1_0_1_1'  className={ "text-left"}     >{`23/09/2024`} </div></div></div>
                <div id='bloc1_1_1x1_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x1_1_1_0'  className={ "p-2 "}     >{`Design - UI/UX`} </div>
                  <div id='bloc1_1_1x1_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x1_1_2'  className={ "p-2 text-left"}     >{`Designer UI/UX - Portail d'authentification`} </div>
                <div id='bloc1_1_1x1_1_3'  className={ "p-2 text-left font-bold"}     >{`Le projet consiste à concevoir et améliorer un portail d'authentification pour les utilisateurs de la plateforme AppIsYou, avec une forte attention à la sécurité et à l'expérience utilisateur.`} </div>
                <div id='bloc1_1_1x1_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x1_1_4_0x0'  className={ "p-1 text-sm"}     >{`Création d'une interface utilisateur intuitive pour un portail d'authentification.`} </div>
          <div id='bloc1_1_1x1_1_4_0x1'  className={ "p-1 text-sm"}     >{`Conception des écrans d'inscription, de connexion et de gestion des comptes.`} </div>
          <div id='bloc1_1_1x1_1_4_0x2'  className={ "p-1 text-sm"}     >{`Amélioration de l'expérience utilisateur tout en garantissant une sécurité optimale.`} </div>
          <div id='bloc1_1_1x1_1_4_0x3'  className={ "p-1 text-sm"}     >{`Proposition de différentes itérations de design en tenant compte de l'accessibilité.`} </div></div>
                <div id='bloc1_1_1x1_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x1_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Figma`} </div>
          <div id='bloc1_1_1x1_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Adobe XD`} </div>
          <div id='bloc1_1_1x1_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Sketch`} </div>
          <div id='bloc1_1_1x1_1_5_0x3'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Zeplin`} </div></div>
                <div id='bloc1_1_1x1_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x1_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x1_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x1_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x1_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x1_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x1_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x1_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x1_1_8'  className={ "p-2 "}     >{`Motivation et capacité d'apprentissage`} </div>
                <div id='bloc1_1_1x1_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-white text-primary_color"}     > <div id='bloc1_1_1x1_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x1_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x1_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton text-white bg-primary_color"}    onClick = { bloc1_1_1x1_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x1_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div>
    <div id='bloc1_1_1x2'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x2_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x2_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/chatui1726421595342.png"   alt="undefined" />
                <div id='bloc1_1_1x2_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Développeur Fullstack - Messagerie Instantanée`} </div></div>
              <div id='bloc1_1_1x2_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg"}     > <div id='bloc1_1_1x2_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x2_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x2_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x2_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x2_1_0_1_1'  className={ "text-left"}     >{`23/09/2024`} </div></div></div>
                <div id='bloc1_1_1x2_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x2_1_1_0'  className={ "p-2 "}     >{`Informatique - Développement Fullstack`} </div>
                  <div id='bloc1_1_1x2_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x2_1_2'  className={ "p-2 text-left"}     >{`Développeur Fullstack - Messagerie Instantanée`} </div>
                <div id='bloc1_1_1x2_1_3'  className={ "p-2 text-left font-bold"}     >{`Nous recherchons un stagiaire motivé pour participer au développement d'une solution de messagerie instantanée en temps réel. Ce projet inclut la mise en place d'une base de données NoSQL (MongoDB ou CouchDB) et l'intégration du module dans la plateforme AppIsYou.`} </div>
                <div id='bloc1_1_1x2_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x2_1_4_0x0'  className={ "p-1 text-sm"}     >{`Développement d'un système de messagerie instantanée`} </div>
          <div id='bloc1_1_1x2_1_4_0x1'  className={ "p-1 text-sm"}     >{`Mise en place de la base de données NoSQL avec MongoDB ou CouchDB`} </div>
          <div id='bloc1_1_1x2_1_4_0x2'  className={ "p-1 text-sm"}     >{`Gestion des messages en temps réel avec WebSocket`} </div>
          <div id='bloc1_1_1x2_1_4_0x3'  className={ "p-1 text-sm"}     >{`Intégration du module dans la plateforme AppIsYou`} </div></div>
                <div id='bloc1_1_1x2_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x2_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`React`} </div>
          <div id='bloc1_1_1x2_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Node.js`} </div>
          <div id='bloc1_1_1x2_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`WebSocket`} </div>
          <div id='bloc1_1_1x2_1_5_0x3'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`MongoDB ou CouchDB`} </div>
          <div id='bloc1_1_1x2_1_5_0x4'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`NoSQL`} </div></div>
                <div id='bloc1_1_1x2_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x2_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x2_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x2_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x2_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x2_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x2_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x2_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x2_1_8'  className={ "p-2 "}     >{`Motivation et capacité d'apprentissage`} </div>
                <div id='bloc1_1_1x2_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-primary_color text-white"}     > <div id='bloc1_1_1x2_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x2_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x2_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton bg-white text-primary_color"}    onClick = { bloc1_1_1x2_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x2_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div>
    <div id='bloc1_1_1x3'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x3_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x3_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/wedding1726403123992.jpeg"   alt="undefined" />
                <div id='bloc1_1_1x3_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Designer UI/UX - Application d'organisation d'événements`} </div></div>
              <div id='bloc1_1_1x3_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg bg-primary_color text-white"}     > <div id='bloc1_1_1x3_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x3_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x3_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x3_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x3_1_0_1_1'  className={ "text-left"}     >{`2024-09-20`} </div></div></div>
                <div id='bloc1_1_1x3_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x3_1_1_0'  className={ "p-2 "}     >{`Design - UI/UX`} </div>
                  <div id='bloc1_1_1x3_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x3_1_2'  className={ "p-2 text-left"}     >{`Designer UI/UX - Application d'organisation d'événements`} </div>
                <div id='bloc1_1_1x3_1_3'  className={ "p-2 text-left font-bold"}     >{`Ce projet porte sur la création d'une application innovante permettant de gérer tous types d'événements, avec un accent sur l'ergonomie et une expérience utilisateur fluide.`} </div>
                <div id='bloc1_1_1x3_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x3_1_4_0x0'  className={ "p-1 text-sm"}     >{`Conception d'une application mobile pour organiser des événements.`} </div>
          <div id='bloc1_1_1x3_1_4_0x1'  className={ "p-1 text-sm"}     >{`Création de parcours utilisateurs pour la gestion des invitations, des événements et des notifications.`} </div>
          <div id='bloc1_1_1x3_1_4_0x2'  className={ "p-1 text-sm"}     >{`Développement de maquettes interactives pour valider l'ergonomie et l'esthétique de l'application.`} </div>
          <div id='bloc1_1_1x3_1_4_0x3'  className={ "p-1 text-sm"}     >{`Travail en étroite collaboration avec les équipes de développement pour l'intégration des designs.`} </div></div>
                <div id='bloc1_1_1x3_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x3_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Figma`} </div>
          <div id='bloc1_1_1x3_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`InVision`} </div>
          <div id='bloc1_1_1x3_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Adobe Illustrator`} </div>
          <div id='bloc1_1_1x3_1_5_0x3'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Sketch`} </div></div>
                <div id='bloc1_1_1x3_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x3_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x3_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x3_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x3_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x3_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x3_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x3_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x3_1_8'  className={ "p-2 "}     >{`Motivation et curiosité pour le design événementiel`} </div>
                <div id='bloc1_1_1x3_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-white text-primary_color"}     > <div id='bloc1_1_1x3_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x3_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x3_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton text-white bg-primary_color"}    onClick = { bloc1_1_1x3_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x3_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div>
    <div id='bloc1_1_1x4'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x4_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x4_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/payment1726423014271.png"   alt="undefined" />
                <div id='bloc1_1_1x4_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Développeur Fullstack - Module de Paiement`} </div></div>
              <div id='bloc1_1_1x4_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg"}     > <div id='bloc1_1_1x4_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x4_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x4_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x4_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x4_1_0_1_1'  className={ "text-left"}     >{`23/09/2024`} </div></div></div>
                <div id='bloc1_1_1x4_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x4_1_1_0'  className={ "p-2 "}     >{`Informatique - Développement Fullstack`} </div>
                  <div id='bloc1_1_1x4_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x4_1_2'  className={ "p-2 text-left"}     >{`Développeur Fullstack - Module de Paiement`} </div>
                <div id='bloc1_1_1x4_1_3'  className={ "p-2 text-left font-bold"}     >{`Nous offrons un stage axé sur le développement d'un module de messagerie et de visio-conférence avec intégration d'une base de données relationnelle (MySQL). Ce projet nécessitera la maîtrise des WebSocket pour la messagerie en temps réel et de WebRTC pour la vidéo, avec intégration à la plateforme AppIsYou.`} </div>
                <div id='bloc1_1_1x4_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x4_1_4_0x0'  className={ "p-1 text-sm"}     >{`Développement d'un module de paiement, d'abonnement et de vente en ligne via Stripe`} </div>
          <div id='bloc1_1_1x4_1_4_0x1'  className={ "p-1 text-sm"}     >{`Intégration avec une base de données relationnelle (MySQL)`} </div>
          <div id='bloc1_1_1x4_1_4_0x2'  className={ "p-1 text-sm"}     >{`Intégration du module dans la plateforme AppIsYou`} </div></div>
                <div id='bloc1_1_1x4_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x4_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`React`} </div>
          <div id='bloc1_1_1x4_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Node.js`} </div>
          <div id='bloc1_1_1x4_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`MySQL`} </div></div>
                <div id='bloc1_1_1x4_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x4_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x4_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x4_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x4_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x4_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x4_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x4_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x4_1_8'  className={ "p-2 "}     >{`Motivation et curiosité`} </div>
                <div id='bloc1_1_1x4_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-primary_color text-white"}     > <div id='bloc1_1_1x4_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x4_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x4_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton bg-white text-primary_color"}    onClick = { bloc1_1_1x4_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x4_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div>
    <div id='bloc1_1_1x5'  className={ "w-full flex flex-col md:flex-row h-full shadow-xl max-w-screen-xl rounded-xl border border-gray-200 items-stretch justify-center font-app-1 mb-12 rounded-xl"}     > <div id='bloc1_1_1x5_0'  className={ "w-full md:w-1/2 flex-grow relative"}     > <img  id='bloc1_1_1x5_0_0'  className={ "object-cover w-full h-auto"}  src= "https://fs.appisyou.com/apps/project/coaching1726403118433.jpeg"   alt="undefined" />
                <div id='bloc1_1_1x5_0_1'  className={ "text-left absolute top-0 left-0 w-full font-bold p-6 text-primary_color text-shadow text-3xl"}     >{`Designer UI/UX - Application de coaching`} </div></div>
              <div id='bloc1_1_1x5_1'  className={ "md:w-1/2 flex flex-col w-full items-start p-6 justify-around border-l md:text-lg bg-primary_color text-white"}     > <div id='bloc1_1_1x5_1_0'  className={ "p-2 w-full justify-between flex"}     > <div id='bloc1_1_1x5_1_0_0'  className={ "p-2 text-3xl"}     >{`Stage`} </div>
                  <div id='bloc1_1_1x5_1_0_1'  className={ " p-2 "}     > <div id='bloc1_1_1x5_1_0_1_0'  className={ "text-xs"}     >{`à partir du`} </div>
                    <div id='bloc1_1_1x5_1_0_1_1'  className={ "text-left"}     >{`23/09/2024`} </div></div></div>
                <div id='bloc1_1_1x5_1_1'  className={ "w-full justify-between flex"}     > <div id='bloc1_1_1x5_1_1_0'  className={ "p-2 "}     >{`Design - UI/UX`} </div>
                  <div id='bloc1_1_1x5_1_1_1'  className={ "p-2 "}     >{`Béziers, France`} </div></div>
                <div id='bloc1_1_1x5_1_2'  className={ "p-2 text-left"}     >{`Designer UI/UX - Application de coaching`} </div>
                <div id='bloc1_1_1x5_1_3'  className={ "p-2 text-left font-bold"}     >{`Vous travaillerez sur la conception d'une application de coaching en ligne, permettant aux utilisateurs de suivre leurs progrès et de planifier leurs sessions avec leurs coachs, le tout dans une interface intuitive.`} </div>
                <div id='bloc1_1_1x5_1_4'  className={ "w-full text-right border-r px-2"}     > <div id='bloc1_1_1x5_1_4_0x0'  className={ "p-1 text-sm"}     >{`Conception d'une interface utilisateur pour une application de coaching en ligne.`} </div>
          <div id='bloc1_1_1x5_1_4_0x1'  className={ "p-1 text-sm"}     >{`Développement d'écrans pour la gestion des sessions de coaching, des suivis de progrès et des notifications.`} </div>
          <div id='bloc1_1_1x5_1_4_0x2'  className={ "p-1 text-sm"}     >{`Création de wireframes et de prototypes interactifs pour valider l'ergonomie du parcours utilisateur.`} </div>
          <div id='bloc1_1_1x5_1_4_0x3'  className={ "p-1 text-sm"}     >{`Collaboration avec l'équipe de développement pour intégrer les designs dans la plateforme AppIsYou.`} </div></div>
                <div id='bloc1_1_1x5_1_5'  className={ "p-2 w-full flex items-end justify-end flex-wrap"}     > <div id='bloc1_1_1x5_1_5_0x0'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Figma`} </div>
          <div id='bloc1_1_1x5_1_5_0x1'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Adobe XD`} </div>
          <div id='bloc1_1_1x5_1_5_0x2'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Marvel App`} </div>
          <div id='bloc1_1_1x5_1_5_0x3'  className={ "p-2 text-xs rounded-full bg-primary_color text-white border"}     >{`Zeplin`} </div></div>
                <div id='bloc1_1_1x5_1_6'  className={ "flex w-full"}     > <div id='bloc1_1_1x5_1_6_0'  className={ "w-full justify-between flex flex-wrap"}     > <div id='bloc1_1_1x5_1_6_0_0'  className={ "p-2 "}     >{`2 à 6 mois`} </div>
                    <div id='bloc1_1_1x5_1_6_0_1'  className={ "p-2 "}     >{`Pas d'expérience requise`} </div>
                    <div id='bloc1_1_1x5_1_6_0_2'  className={ "flex"}     > <div id='bloc1_1_1x5_1_6_0_2_0x0'  className={ "p-2 text-left"}     >{`Hybride`} </div>
              <div id='bloc1_1_1x5_1_6_0_2_0x1'  className={ "p-2 text-left"}     >{`Full Remote`} </div></div></div></div>
                <div id='bloc1_1_1x5_1_7'  className={ "p-2 "}     > </div>
                <div id='bloc1_1_1x5_1_8'  className={ "p-2 "}     >{`Grande motivation pour le design UX dans le domaine du coaching`} </div>
                <div id='bloc1_1_1x5_1_9'  className={ "w-full sticky p-2 rounded-xl bottom-4 bg-white text-primary_color"}     > <div id='bloc1_1_1x5_1_9_0'  className={ "p-2 w-full flex justify-between"}     > <div id='bloc1_1_1x5_1_9_0_0'  className={ "p-2 text-xs"}     >{`contact@appisyou.fr`} </div>
                    <a    href="mailto:contact@appisyou.fr?subject=Demande de stage" target='_blank'><div id='bloc1_1_1x5_1_9_0_1'  className={ "p-2 px-4 rounded-full appbutton text-white bg-primary_color"}    onClick = { bloc1_1_1x5_1_9_0_1_onClick } >{`Postuler`} </div></a></div>
                  <div id='bloc1_1_1x5_1_9_1'  className={ "text-right w-full italic pr-2 text-xs"}     >{`Rémunération sur intéressement.`} </div></div></div></div></div></div><div id='bloc2'  className={ "flex items-center bg-zinc-700 safearea w-screen bg-primary_color flex-row-reverse h-32 p-6"}     > <img  id='bloc2_0'  className={ "w-10 h-10 rounded-xl m-2 mr-10   object-cover"}  src="/profiles/ploy26/user.png"   alt="undefined" />
          <div id='bloc2_1'  className={ "p-2 text-white font-bold font-app-1"}     >{`2024`} </div></div></>
  
}

export default Component116Page;
