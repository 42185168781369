
import axios from "axios";

var _project 


export const retrieveProject=()=>{
    return _project;
}

export const setToken = (token, authUser) => {
    if (token) {
        localStorage.setItem('token', token)
        localStorage.setItem('authUser', JSON.stringify(authUser))
    }
    else {
        localStorage.removeItem('token');
        localStorage.removeItem('authUser');
    }
} 

export const updateAuthuser=(authUser)=>{
    localStorage.setItem('authUser', JSON.stringify(authUser))
}


export const getAuthUser = () => {

    var result = localStorage.getItem('authUser')
    if (result) {
        try {
            return JSON.parse(result)
        }
        catch (err) {
            //console.log(err)
        }
    } else return null;

}

export const getApp=()=>{

return "client";
}


export const api=async (endpoint, params)=>{
    if(!_project)
        {
            await projectInit() 
        }
   return  await  _project.api(endpoint, params)
} 



export const projectInit = async () => {

  
    var headers = {};
    var token = localStorage.getItem('token')
    if (token)     headers['Authorization'] = "Bearer " + token;
     headers["Mftc-Application"] =getApp();
     
    try{
       
     
    const response = await fetch( process.env.REACT_APP_MFTC_SERVER_URL + `${   process.env.REACT_APP_PROJECT_NAME}/init/0` , {
        method: 'get',
        headers: { ...headers }
    });
    const project = await response.json();
   if( project.updatedUser){
    setToken( project.updatedUser.token, project.updatedUser.user)
   }

    if( project )
    {
        project.isDev =   window.location.href.split(":30").length>1;
         project.api = async (endpoint, params) => {
        if(params?.cache)
        {
            const  cache = localStorage.getItem(params.cache) 
            if( cache) return JSON.parse(cache);
        }

        if ( !project.endpoints[endpoint] ) {
            console.log("No endpoint like this', endpoint: " + endpoint)
            return;
        }
        var url =process.env.REACT_APP_MFTC_SERVER_URL+ process.env.REACT_APP_PROJECT_NAME + project.endpoints[endpoint].url+"/";
       // console.log("url",url,params.args)
        if (params && params.args) {
            Object.keys(params.args).forEach(key => {
                url = url.split(":" + key+"/").join(params.args[key]+"/");
            })
        }
        url = url.substring(0,url.length-1)
       // console.log("url",url,params.args)
        const  domain = localStorage.getItem('domain')
        if(domain) url+=`?domain=${domain}`
      

        var response;
        var headers = {};
        var token = localStorage.getItem('token')
        if (token)       headers['Authorization'] = "Bearer " + token;
        
        if (process.env.REACT_APP_MFTC_APP) headers["Mftc-Application"] = getApp()

        switch (project.endpoints[endpoint].method) {

            case "get":
                response = await fetch(url, {
                    method: 'GET',

                    headers
                });

                break;
            case "post":
                response = await fetch(url,
                    {
                        method: 'POST',
                        headers: { ...headers, "Content-Type": "application/json" },
                        body: JSON.stringify(params.body)
                    })


              //      contentType: 'text/event-stream',


                break;

case "postform":
   
var body = params.body;
if(!body){
 const formData = new FormData();
 if (params.form) {
     Object.keys(params.form).forEach(key => {
         formData.append(key, params.form[key]);
     })
     body=formData
 }
}

response = await axios.post(url, body, { 
 onUploadProgress: params.onUploadProgress, 
 headers: {
    ...headers,
    //     "Content-Type": "multipart/form-data"
}
});
break;
            case "postform_old":
               var body = params.body;
               if(!body){
                const formData = new FormData();
                if (params.form) {
                    Object.keys(params.form).forEach(key => {
                        formData.append(key, params.form[key]);
                    })
                    body=formData
                }
            }

                response = await fetch(url,
                    {
                        method: 'POST',
                        body,
                        headers: {
                            ...headers,
                            //     "Content-Type": "multipart/form-data"
                        }
                    }
                );

                break;
            case "put":
                response = await fetch(url,
                    {
                        method: 'PUT',
                        headers: {
                            ...headers,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(params.body)
                    });
                break;
            case "delete":
                response = await fetch(url,
                    {
                        method: 'DELETE',

                        headers: {
                            ...headers,
                            //     "Content-Type": "multipart/form-data"
                        }
                    }
                );
                break;
default :

        }





        if (response) {
            try {


        if(   project.endpoints[endpoint].contentType=== 'text/event-stream')
        {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
    
            const readStream = () => {
                reader.read().then(({ done, value }) => {
                    if (done) {
                        console.log('Fin de la lecture');
                        return;
                    }
                    const decodedValue = decoder.decode(value, { stream: true });
                    console.log('Données reçues :', decodedValue);
                    if( params.eventStreamListener)  params.eventStreamListener(decodedValue)

                    readStream();
                }).catch(error => {
                    console.error('Erreur lors de la lecture du flux :', error);
                });
            };
    
            readStream();

        }
        else
{
                const result = await response.json();

                if(params?.cache){
                    localStorage.setItem(params.cache, JSON.stringify(result));
                }
                return result;
            }

            } catch (err) {
                console.log("JSON ERROR", err, response);

                return null
            }
        }
        return {}
    }
project.uploadFile=async (files,onUploadProgress,dir=null,noRename=false)=>{

    var headers = {};
    var token = localStorage.getItem('token')
    if (token)       headers['Authorization'] = "Bearer " + token;
    
    if (process.env.REACT_APP_MFTC_APP) headers["Mftc-Application"] = getApp()

 var namefiles = []
     const formData = new FormData();
     
       for(var i=0 ;i<files.length;i++){
        var file = files[i]
            var parts= file.name.split(".")
            var ext = parts.pop()
            var fname = parts.join(".") +(noRename ? "" :Date.now()) + "."+ext
            namefiles.push(fname)
            formData.append(fname, file, fname);

         }

       if(dir)  formData.append("dir", dir);
         formData.append("files", JSON.stringify(namefiles));
       var  body=formData
   var response = await axios.post(  process.env.REACT_APP_MFTC_HOST_FILES  +"upload"  , body, { 
     onUploadProgress: onUploadProgress, 
     headers: {
        ...headers,
        //     "Content-Type": "multipart/form-data"
    }
    });

    console.log("Upload Response",response);
var results =  response.data;
return results;
}
project.getFiles=async (dir)=>{

    var headers = {};
    var token = localStorage.getItem('token')
    if (token)       headers['Authorization'] = "Bearer " + token;
    
    if (process.env.REACT_APP_MFTC_APP) headers["Mftc-Application"] = getApp()

   var response = await axios.post(  process.env.REACT_APP_MFTC_HOST_FILES  +"dir"  , {dir,recursive:true}, { 
   
     headers: {
        ...headers,
    }
    });

var results =  response.data;
return results;
}

    project.urlImage = (urlImage)=>{
if( !urlImage) return null
if(  urlImage.substring(0, 4)!=="img_") return urlImage;
//console.log(   url_server.split("/api")[0]   +"/user_img/"+ urlImage);
return      process.env.REACT_APP_MFTC_SERVER_URL.split("/api")[0]   +"/user_img/"+ urlImage


    }

    }

    console.log("projectInit", project);

   //  project.Objects= {};
   //  project.getObject = (args)=>{      return getObject( {...args,project})x  }

      project.getAuthUser = getAuthUser
       project.setToken=setToken;

       _project=project

    return project;

}
catch(e)
{
console.log("error project connection");

const project = {api:()=>{return }}
return project; 
}

}



