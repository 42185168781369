// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component113Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "p-6 flex md:flex-row flex-col justify-center w-screen h-screen items-center gap-4 bg-white font-app-1"}     > <img  id='bloc0_0'  className={ "p-2 bg-white h-1/2   object-cover"}  src="https://fs.appisyou.com/apps/project/logo_complet1721662241521.png"   style = { {"maxWidth":"50vw","maxHeight":"50vw"}} alt="undefined" />
          <div id='bloc0_1'  className={ "flex flex-col justify-start items-start md:w-1/3"}     > <div id='bloc0_1_0'  className={ "my-2 text-2xl font-bold whitespace-pre-line md:text-left text-center"}     >{` Venez bientôt découvrir AppIsYou !!!`} </div>
            <div id='bloc0_1_1'       > </div></div></div></>
  
}

export default Component113Page;
